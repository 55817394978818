import React from "react";
import GetQuoteTV from "./GetQuoteTV";
import ImageSliderTv from "./ImageSliderTv";
import ReviewsTV from './ReviewsTV';

import TV32 from "../assets/image/tv_mounting_59.png";
import TV60 from "../assets/image/tv_mounting_60.png";
import Check from "../assets/tv_installation_400/tv_mounting_service_logo.webp";

function TV_Mounting() {
  const city = "Orange County"

  const oldPrice32 = "$169";
  const newPrice32 = "September $119";

  const oldPrice60 = "$229";
  const newPrice60 = "September $149";

  return (
    <section className="features" id="tv_mounting_service">
      <div className="container">
        <div className="container_tv">

<div className="mobile-container">
        
<div className="image-container">
  <div className="image-wrapper">
    <img src={TV32} alt="59 TV Mouning Service" className="custom-image" />
    <p className="image-caption">
      32"-59"&nbsp; 
      <span className="old-price">{oldPrice32}</span>
      <br />
      <br />
      <span className="new-price-wrapper">
        <span className="new-price">{newPrice32}</span>
      </span>
    </p>
  </div>

  <div className="image-wrapper">
    <img src={TV60} alt="60 TV Mouning Service" className="custom-image" />
    <p className="image-caption">
      60" or larger&nbsp;
      <span className="old-price">{oldPrice60}</span>
      <br />
      <br />
      <span className="new-price-wrapper">
        <span className="new-price">{newPrice60}</span>
      </span>
    </p>
  </div>

</div>


<div className="text-block-tv">
    <p>
      *Prices are based on drywall installations without wire concealment or additional equipment setup. 
      Please ensure you have a compatible <strong>TV mount</strong>. If not, we can source the best mounts tailored to your needs and preferences based on our expertise.
    </p>
  </div>
  </div>


          {/* <div className="text">
            <div className="tables-container">
            <div className="table-wrapper">
              <h3>Standard Wall TV Mounting Service Pricing</h3>

              <div className="image-container">
                <img src={TV32} alt="Description" className="custom-image" />
                <img src={TV60} alt="Description" className="custom-image" />
              </div>

              <br></br>
              <table className="table-container">
                <thead>
                  <tr>
                    <th>TV Size</th>
                    <th>Installation Cost</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>32" - 59"</td>
                    <td>$129</td>
                  </tr>
                  <tr>
                    <td>60" and above</td>
                    <td>$189</td>
                  </tr>
                </tbody>
              </table>
              <div className="note">
                Prices are based on drywall installations without wire
                concealment or additional equipment setup. Please ensure you
                have a compatible TV mount. If not, we offer a selection of
                mounts starting from $70 to $150, depending on the type and
                model.
              </div>
            </div>

            <div className="table-wrapper">
              <h3 className="table-title">Additional Services</h3>
              <br></br>
              <table className="table-container">
                <thead>
                  <tr>
                    <th>Service</th>
                    <th>Cost</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>TV Mounting Brackets</td>
                    <td>$70 - $150</td>
                  </tr>
                  <tr>
                    <td>Wire Concealment in Wall</td>
                    <td>$99</td>
                  </tr>
                  <tr>
                    <td>Device Setup (e.g., Soundbars, Gaming Consoles)</td>
                    <td>$79 per unit</td>
                  </tr>
                </tbody>
              </table>
              </div>
            </div>
          </div> */}

        <div className="mobile-container">
        <div>
            <ul class="tv_mounting_skills_list">
              <p class="tv_mounting_skills">No Hidden Fees</p>
              <p class="tv_mounting_skills">100% Satisfaction Guaranteed</p>
              <p class="tv_mounting_skills">Backed by 1 Year Warranty</p>
              <p class="tv_mounting_skills">Flexible Scheduling</p>
              <p class="tv_mounting_skills">Urgent Installations</p>
              <p class="tv_mounting_skills">{city} Local Community Support</p>
            </ul>

          </div>
        </div>

          <div className="mobile-container">
          <ImageSliderTv />
          <br></br>
          <br></br>
          </div>
            

      <div className="mobile-container">
      <ReviewsTV />
      <br></br>
      <br></br>
      </div>


          <div className="container_tv_qoute_form">
            <br></br>
            <GetQuoteTV />
            <br></br>
            <br></br>
          </div>

          <div className="mobile-container">
          <div className="text">
            <h3>Professional Installation of Any TV Type in {city}</h3>
            <p>
              We specialize in mounting TVs of various types and sizes,
              including OLED, LED, LCD, and plasma screens. Regardless of your
              TV model or size, our experienced specialists will ensure flawless
              installation.
            </p>
            <h3>Safety and Reliability</h3>
            <p>
              Your precious TVs are in safe hands. We strictly adhere to all
              safety standards and use only quality equipment for mounting. Your
              TV will be securely installed without the risk of falling.
            </p>

            <h3>Seamless Installation Experience</h3>
            <p>
              Unlike aggregators, we provide a hands-on, personalized service
              tailored to your specific needs. You'll benefit from direct
              communication with our experienced specialists, ensuring a smooth
              and seamless TV installation process.
            </p>

            <h3>Personalized Approach</h3>
            <p>
              We understand that every TV installation is unique. That's why we
              offer a personalized approach to each project, considering your
              preferences and the specifics of your space. We'll make sure your
              TV looks perfect in your interior.
            </p>
            <h3>Expert Consultation and Support</h3>
            <p>
              Our team of experts is always ready to provide you with
              professional consultation on TV installation. We'll answer all
              your questions and provide full support from choosing the optimal
              placement to completing the installation process.
            </p>

            <img
              src={Check}
              alt="Expertise Icon"
              style={{
                height: "350px",
                marginRight: "40px",
                borderRadius: "10px",
              }}
            />

            <h3>Supporting Our {city} Local Community</h3>
            <p>
              We're a local {city} business, and choosing us helps our
              community thrive. Aggregators are often large corporations, not
              always attuned to local needs.
            </p>
            <h3>Customer Satisfaction Guaranteed</h3>
            <p>
              Our main goal is your complete satisfaction. We strive to exceed
              your expectations in all aspects of our work, providing
              high-quality service and outstanding results. Your trust is our
              greatest reward.
            </p>
            <h3>Competitive Prices and Flexible Schedule</h3>
            <p>
              We offer competitive prices for our services and a flexible work
              schedule to make the TV installation process as convenient and
              accessible for you as possible. We aim to make your life easier by
              offering high-quality services at affordable prices.
            </p>
          </div>
        </div>
        <br></br>
        <div className="mobile-only">
          <a
            className="get-quote__link action-button"
            id="bottomSmsButtonTvPage"
            href="sms:949-438-0343"
          >
            Book NOW!
          </a>
        </div>
        </div>
      </div>

      

      <div className="get-quote">
        <div className="get-quote__container container">
          <div className="get-quote__p">
            Elevate Your Living Space with Expert {city} TV
            Mounting Services!
          </div>
          <a
            className="get-quote__link action-button"
            id="bottomSmsButtonTvPage"
            href="sms:+19494380343"
          >
            Text Us 24/7
          </a>
        </div>
      </div>
    </section>
  );
}

export default TV_Mounting;
