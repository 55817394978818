import React from "react";

function TermsOfService() {
  return (
    <section class="terms_of_service" id="terms_of_service">
      <div className="support_container">
        <h1>Terms of Service</h1>
        <p class="terms-of-service__content">
          Welcome to Orange County Furniture Assembly & TV Mounting Service!
          These Terms of Service ("Terms") govern your use of our website and
          services. By accessing or using our website, you agree to comply with
          these Terms. Please read them carefully.
        </p>

        <h2>1. Use of Our Services:</h2>
        <p>
          You must be at least 18 years old to use our services. By using our
          website, you represent that you are at least 18 years old or have the
          consent of a parent or guardian.
        </p>

        <h2>2. User Accounts:</h2>
        <p>
          You may be required to create a user account to access certain
          features of our website. You are responsible for maintaining the
          confidentiality of your account credentials and for all activities
          that occur under your account.
        </p>

        <h2>3. Prohibited Conduct:</h2>
        <p>
          You agree not to engage in any of the following prohibited activities:
        </p>
        <ul>
          <li>Violating any laws or regulations.</li>
          <li>
            Infringing upon the rights of others, including intellectual
            property rights.
          </li>
          <li>
            Uploading or transmitting viruses, malware, or other malicious code.
          </li>
          <li>
            Attempting to gain unauthorized access to our systems or data.
          </li>
          <li>Interfering with the operation of our website or services.</li>
          <li>
            Engaging in any conduct that could harm California Furniture
            Assembly or its users.
          </li>
        </ul>

        <h2>4. Intellectual Property:</h2>
        <p>
          All content on our website is the property of California Furniture
          Assembly or its licensors and is protected by copyright and other
          intellectual property laws. You may not use, reproduce, or distribute
          any content from our website without our prior written consent.
        </p>

        <h2>5. Disclaimer of Warranties:</h2>
        <p>
          Our website and services are provided on an "as is" and "as available"
          basis, without any warranties of any kind, express or implied. We do
          not guarantee that our website will be error-free or uninterrupted, or
          that any defects will be corrected.
        </p>

        <h2>6. Limitation of Liability:</h2>
        <p>
          To the maximum extent permitted by law, California Furniture Assembly
          shall not be liable for any indirect, incidental, special,
          consequential, or punitive damages, or any loss of profits or revenue,
          arising out of or in connection with your use of our website or
          services.
        </p>

        <h2>7. Indemnification:</h2>
        <p>
          You agree to indemnify and hold California Furniture Assembly harmless
          from any claims, losses, damages, liabilities, and expenses (including
          attorneys' fees) arising out of or related to your use of our website
          or services, your violation of these Terms, or your violation of any
          rights of another.
        </p>

        <h2>8. Governing Law:</h2>
        <p>
          These Terms shall be governed by and construed in accordance with the
          laws of the State of California, without regard to its conflict of
          laws principles. Any dispute arising out of or in connection with
          these Terms shall be resolved exclusively in the state or federal
          courts located in Los Angeles County, California.
        </p>

        <h2>9. Changes to Terms:</h2>
        <p>
          We reserve the right to update or modify these Terms at any time
          without prior notice. By continuing to use our website after such
          changes, you agree to be bound by the revised Terms.
        </p>

        <h2>10. Contact Us:</h2>
        <p>
          If you have any questions or concerns about these Terms, please
          contact us at:
        </p>

        <address>
          Orange County Furniture Assembly & TV Mounting Service
          <br />
          9920 Irvine Center Dr,
          <br />
          Irvine, CA 92618
          <br />
          Email: info@hubpro.us
          <br />
          Phone: (949) 438-0343
          <br />
        </address>

        <p>These Terms were last updated on 04/13/2024.</p>
      </div>
    </section>
  );
}

export default TermsOfService;
