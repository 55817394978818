import React from 'react';
import { useParams } from 'react-router-dom';
import ImageSlider from '../ImageSlider';
import Reviews from './ReviewsTV';
import Logo from "../assets/furniture_installation_400/furniture_assembly_2730.webp";
import GetQuote from "./GetQuote";

const services = [
  { name: "Bed Assembly", link: "/bed-assembly" },
  { name: "Bench Assembly", link: "/bench-assembly" },
  { name: "Bookshelf Assembly", link: "/bookshelf-assembly" },
  { name: "Bunk Bed Assembly", link: "/bunk-bed-assembly" },
  { name: "Chair Assembly", link: "/chair-assembly" },
  { name: "Couch Assembly", link: "/couch-assembly" },
  { name: "Crib Assembly", link: "/crib-assembly" },
  { name: "Desk Assembly", link: "/desk-assembly" },
  { name: "Disassemble Furniture", link: "/disassemble-furniture" },
  { name: "Dresser Assembly", link: "/dresser-assembly" },
  { name: "Furniture Assembly", link: "/furniture-assembly" },
  { name: "Patio Furniture Assembly", link: "/patio-furniture-assembly" },
  { name: "Shelf Assembly", link: "/shelf-assembly" },
  { name: "Table Assembly", link: "/table-assembly" },
  { name: "Shed Assembly", link: "/shed-assembly" },
  { name: "Wardrobe Assembly", link: "/wardrobe-assembly" },
  { name: "IKEA Assembly Service", link: "/ikea-assembly" },
  { name: "Entertainment Center Assembly", link: "/entertainment-center-assembly" }
];

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function Furniture_Assembly() {
  const city = "Orange County";
  const { service } = useParams();
  const currentService = services.find(s => s.link === `/${service}`) || { name: 'Furniture Assembly' };

  return (
    <section id="furniture_assembly">
      <div className="features__container container">
        <div className="mobile-container">
          <GetQuote serviceName={currentService.name} city={city} />
        </div>
        <div className="mobile-container">
      <div className="container_tv">
          <Reviews city={city} />
          <br />
        </div>
      </div>
        <div className="mobile-container">
        <ul class="furniture_skills_list">
  <li class="furniture_skills">Guaranteed On-Time Arrival</li>
  <li class="furniture_skills">Same-Day {city} Assembly Service Available</li>
  <li class="furniture_skills">Assembly Assurance: We'll Fix Any Issues Free of Charge</li>
  <li class="furniture_skills">VIP Assembly Service: Skip the Queue, Get Priority Service</li>
  <li class="furniture_skills">Unlock Great Prices: Save on Furniture Assembly for Your Move</li>
  <li class="furniture_skills">Price Negotiable: Let's Find the Best Deal Together</li>
</ul>

</div>
        <div className="mobile-container">
          <h2 className="about__h1">
            Browsing Through Our {city} Portfolio of Services and Professional Equipment
          </h2>
          <br />
          <ImageSlider />
          <br /><br />
        </div>




      <div className="mobile-container">

      
        <h2 className="features__h1" style={{ color: 'orange' }}>
          {city} {currentService.name} Service<p></p>
        </h2>
        <h2 className="features__h1">
          Book Your Hassle-Free {currentService.name} Near Me Today!
        </h2>
        <div className="features__list-container">
          <ul className="features__list">
            <li className="features__item"> 
              <p className="features__text_header">Top-Notch Transparent Pricing</p>
              <p className="features__text">
                We take pride in offering the best furniture assembly prices in the 
                market, ensuring our customers' peace of mind with no hidden fees.
              </p>
            </li>
            <li className="features__item">
              <p className="features__text_header">{city} Local Community Support</p>
              <p className="features__text">
                We're a local {city} business, and choosing us helps our community thrive. Aggregators are often large corporations, not always attuned to local needs.
              </p>
            </li>
            <li className="features__item">
              <p className="features__text_header">Rapid Response Time</p>
              <p className="features__text">
                Experience the advantage of quick responses when you opt for our services. Simply give us a call or leave a message, and we'll get back to you within 30 minutes. No lengthy forms to fill out or hours of waiting.
              </p>
            </li>
          </ul>
          <ul className="features__list">
            <li className="features__item">
              <p className="features__text_header">Personalized Appointment Times</p>
              <p className="features__text">
                Experience personalized service with our flexible scheduling, offering appointments every day of the week, including holidays, to suit your individual preferences and commitments.
              </p>
            </li>
            <li className="features__item">
              <p className="features__text_header">Skilled Professionals</p>
              <p className="features__text">
                As a family-owned business, we personally select each of our experts, guaranteeing years of experience, quality workmanship, and meticulous attention to detail on every {currentService.name} project.
              </p>
            </li>
            <li className="features__item">
              <p className="features__text_header">Personal Accountability</p> 
              <p className="features__text">
                As your neighbor, I personally stand behind every job, ensuring accountability and satisfaction with our work.
              </p>
            </li>
          </ul>
        </div>
        <div>
          <a
            className="get-quote__link action-button"
            id="bottomSmsButtonFurniturePage"
            href="sms:+19494380343"
          >
            Text Us 24/7
          </a>
          <br></br>
        </div>
        </div>

        <div className="services-grid">
          {services.map((service, index) => (
            <a 
              key={index} 
              href={service.link} 
              className="service-button"
            >
              {service.name}
            </a>
          ))}
        </div>

        <br></br>
        <br></br>
        
        <div className="mobile-container">
        <div
          className="features__container container"
          style={{ display: "flex", alignItems: "center" }}
        >
          <a href="/furniture-assembly-tools-for-handyman">
            <img
              src={Logo}
              alt="Cordless Drill"
              className="image_article"
            />
          </a>
          <a
            href="/furniture-assembly-tools-for-handyman"
            style={{
              textDecoration: "none",
              display: "flex",
              alignItems: "center",
            }}
          >
            <p
              className="features__text"
              style={{ margin: "0", textAlign: "center" }}
            >
              <span style={{ textDecoration: "underline" }}>
                Click here to read more:
              </span>{" "}
              Seamless Assembly Solutions:<br />
              Professional Tools and Support for Every Project
            </p>
          </a>
        </div>
        </div>


        <br />
      </div>
    </section>
  );
}

export default Furniture_Assembly;
