import React from 'react';
import Slider from 'react-slick';
import Google_Reviews from '../assets/image/google_g_icon.webp';
import Yelp_Reviews from '../assets/image/yelp_logo.webp';
import Liam from '../assets/image/liam.png';
import Vk from '../assets/image/vk.png';
import Alex from '../assets/image/alex.png';
import Boris from '../assets/image/boris.png';
import Buzz from '../assets/image/buz.png';
import Il from '../assets/image/il.png';
import El from '../assets/image/el.png';
import work from '../assets/image/alex_furniture.jpeg';
import L1 from '../assets/image/100_Liam.webp';
import L2 from '../assets/image/100_Liam2.webp';
import Ga from '../assets/image/120s.jpg';
import Bijan from '../assets/image/bijan.png';
import DefaultYelpAvatar from '../assets/image/default.png';
import YelpFavicon from '../assets/image/yelp_favicon.png';
import GoogleFavicon from '../assets/image/favicon_google.png';


function ReviewsTV() {
    const city = "Orange County";

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 4, // Отображать по 2 отзыва за раз
        slidesToScroll: 1,
        adaptiveHeight: true,
        responsive: [
            {
                breakpoint: 769,
                settings: {
                    slidesToShow: 1, // На мобильных устройствах показывать по одному отзыву
                },
            },
        ],
    };

    return (
        
<section className="ReviewsTV">
	<div>
		<h2 className="reviews-tv__header">
                    {/* Real <span style={{ color: 'orange', fontWeight: 'bold' }}>5-Star</span>Yelp and Google Reviews About Us from Your {city} Neighbors */}


                    Real <span style={{ color: 'orange', fontWeight: 'bold' }}>5-Star </span>
<span style={{ display: 'inline-block', verticalAlign: 'middle' }}>
  <img src={YelpFavicon} alt="Yelp favicon" style={{ width: '24px', height: '24px', marginLeft: '10px', marginRight: '3px' }} />
</span>
{' '}Yelp and{' '}
<span style={{ display: 'inline-block', verticalAlign: 'middle' }}>
  <img src={GoogleFavicon} alt="Google favicon" style={{ width: '24px', height: '24px', marginLeft: '10px', marginRight: '3px' }} />
</span>
{' '}Google Reviews About Us from Your {city} Neighbors

                </h2>
		<Slider {...settings} className="reviews-tv__slider">
        <div className="reviews-tv__review">
				<img src={Yelp_Reviews} alt="Google Icon" className="reviews-tv__icon" />
				<img src={DefaultYelpAvatar} alt="Pamela TV Mounting Services" className="reviews-tv__avatar_2" />
				<div className="reviews-tv__author-wrapper">
					<a
                                className="reviews-tv__author"
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{ textDecoration: 'none' }}
                                href="https://yelp.com/biz/hubpro-furniture-assembly-irvine?hrid=6CtMUS_1CzrI5hKWHUorGA&utm_medium=copy_link&utm_source=biz_post_share&l=en_US"
                            >
                                Pamela S.
                            </a>
					<div className="reviews-tv__date">June 19, 2024</div>
					<div className="reviews-tv__stars">★★★★★</div>
				</div>
				<div className="reviews-tv__content">
					<p>
                    I am very happy with Roman. He put my dining room table and chairs together. He was professional and did a great job!

</p>
				</div>
			</div>

        <div className="reviews-tv__review">
				<img src={Google_Reviews} alt="Google Icon" className="reviews-tv__icon" />
				<img src={Bijan} alt="Bijan Gilani TV Mounting Services" className="reviews-tv__avatar_2" />
				<div className="reviews-tv__author-wrapper">
					<a
                                className="reviews-tv__author"
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{ textDecoration: 'none' }}
                                href="https://maps.app.goo.gl/hPCcwbxVsyb5pDbA9"
                            >
                                Bijan Gilani
                            </a>
					<div className="reviews-tv__date">June 11, 2024</div>
					<div className="reviews-tv__stars">★★★★★</div>
				</div>
				<div className="reviews-tv__content">
					<p>
                    I ordered an office desk and a drawer set from Amazon, which arrived unassembled. At first, I thought I could assemble them myself, so I went to work. To my surprise, the job required someone with good knowledge and training to do the task...                            
					</p>
				</div>
			</div>

            

			<div className="reviews-tv__review">
				<img src={Google_Reviews} alt="Google Icon" className="reviews-tv__icon" />
				<img src={Liam} alt="Liam TV Mounting Services" className="reviews-tv__avatar_2" />
				<div className="reviews-tv__author-wrapper">
					<a
                                className="reviews-tv__author"
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{ textDecoration: 'none' }}
                                href="https://g.co/kgs/SdrQf1e"
                            >
                                Liam Shillington
                            </a>
					<div className="reviews-tv__date">June 9, 2024</div>
					<div className="reviews-tv__stars">★★★★★</div>
				</div>
				<div className="reviews-tv__content">
					<p>
                            Great experience with Roman.. he set up 2 TVs a 55 and 75 with no issues and had great customer service
                            
						<img src={L1} alt="Liam TV Mounting Services" />
					</p>
				</div>
			</div>

            <div className="reviews-tv__review">
				<img src={Yelp_Reviews} alt="Google Icon" className="reviews-tv__icon" />
				<img src={Ga} alt="Liam TV Mounting Services" className="reviews-tv__avatar_2" />
				<div className="reviews-tv__author-wrapper">
					<a
                                className="reviews-tv__author"
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{ textDecoration: 'none' }}
                                href="https://yelp.com/biz/hubpro-furniture-assembly-irvine?hrid=tjyFRROkUi_BrP9nP_ENng&utm_medium=copy_link&utm_source=biz_post_share&l=en_US"
                            >
                                Gayani D.
                            </a>
					<div className="reviews-tv__date">June 15, 2024</div>
					<div className="reviews-tv__stars">★★★★★</div>
				</div>
				<div className="reviews-tv__content">
					<p>
                    Made three items for me. On time friendly, super capable. I will hire again. I appreciate their fast and excellent service. Such a fair price too. Trustworthy. A great experience overall.
                            					</p>
				</div>
			</div>

			<div className="reviews-tv__review">
				<img src={Google_Reviews} alt="Google Icon" className="reviews-tv__icon" />
                <img src={Vk} alt="Viktoryia TV Mounting Services" className="reviews-tv__avatar_2" />
				<div className="reviews-tv__author-wrapper">
					<a
                                className="reviews-tv__author"
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{ textDecoration: 'none' }}
                                href="https://g.co/kgs/av7bmXc"
                            >
                                Viktoryia K
                            </a>
                            <div className="reviews-tv__date">April 23, 2024</div>
				<div className="reviews-tv__stars">★★★★★</div>
				</div>
				<div className="reviews-tv__content">
					<p>
                            The furniture assemblers are just great! They assembled everything quickly, carefully, without any garbage. Very polite and pleasant people. Thank you very much!!
                            </p>
				</div>
			</div>



			<div className="reviews-tv__review">
				<img src={Google_Reviews} alt="Google Icon" className="reviews-tv__icon" />
                <img src={Alex} alt="Alex Avatar" className="reviews-tv__avatar_2" />

				<div className="reviews-tv__author-wrapper">
					<a
                                className="reviews-tv__author"
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{ textDecoration: 'none' }}
                                href="https://g.co/kgs/K7x3e8F"
                            >
                                Aliaksandr K
                            </a>
                            <div className="reviews-tv__date">May 2, 2024</div>
				<div className="reviews-tv__stars">★★★★★</div>
				</div>

				<div className="reviews-tv__content">
					<p>
                            Just bought a couch and a drawer stand from Ikea, guys assembled everything quickly for a fair price. No junk, no damage - a goto service.
						<p></p>
						<img src={work} alt="Alex Avatar" />
					</p>
				</div>
			</div>

            <div className="reviews-tv__review">
				<img src={Yelp_Reviews} alt="Google Icon" className="reviews-tv__icon" />
				<img src={DefaultYelpAvatar} alt="Jerry TV Mounting Services" className="reviews-tv__avatar_2" />
				<div className="reviews-tv__author-wrapper">
					<a
                                className="reviews-tv__author"
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{ textDecoration: 'none' }}
                                href="https://yelp.com/biz/hubpro-furniture-assembly-irvine?hrid=8R9Cse7qM-A5haOS6KMsPA&utm_medium=copy_link&utm_source=biz_post_share&l=en_US"
                            >
                                Jerry
                            </a>
					<div className="reviews-tv__date">June 20, 2024</div>
					<div className="reviews-tv__stars">★★★★★</div>
				</div>
				<div className="reviews-tv__content">
					<p>
                    Roman was very nice to work with. He did a great job at a fair price. He communicated well about arrival time and was a genuinely nice guy.

</p>
				</div>
			</div>

			<div className="reviews-tv__review">
				<img src={Google_Reviews} alt="Google Icon" className="reviews-tv__icon" />
                <img src={Boris} alt="Boris Avatar" className="reviews-tv__avatar_2" />

				<div className="reviews-tv__author-wrapper">
					<a
                                className="reviews-tv__author"
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{ textDecoration: 'none' }}
                                href="https://g.co/kgs/LYMFSBs"
                            >
                                Boris L
                            </a>
                            <div className="reviews-tv__date">May 20, 2024</div>
				<div className="reviews-tv__stars">★★★★★</div>
				</div>

				<div className="reviews-tv__content">
					<p>
                            I recently had the pleasure of hiring HubPro for assembling my office furniture. Their expertise and efficiency were truly impressive. They completed the job quickly and paid great attention to every detail. I highly recommend HubPro for anyone ...
                            </p>
				</div>
			</div>

            <div className="reviews-tv__review">
				<img src={Yelp_Reviews} alt="Google Icon" className="reviews-tv__icon" />
				<img src={DefaultYelpAvatar} alt="Yanni G. TV Mounting Services" className="reviews-tv__avatar_2" />
				<div className="reviews-tv__author-wrapper">
					<a
                                className="reviews-tv__author"
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{ textDecoration: 'none' }}
                                href="https://yelp.com/biz/hubpro-furniture-assembly-irvine?hrid=Ldicl0nob7gO8BdIJe_GbA&utm_medium=copy_link&utm_source=biz_post_share&l=en_US"
                            >
                                Yanni G.
                            </a>
					<div className="reviews-tv__date">June 21, 2024</div>
					<div className="reviews-tv__stars">★★★★★</div>
				</div>
				<div className="reviews-tv__content">
					<p>
                    Roman and his associate helped me assemble two desks. He was professional, reliable and price was reasonable. Would use again.


</p>
				</div>
			</div>

			<div className="reviews-tv__review">
				<img src={Google_Reviews} alt="Google Icon" className="reviews-tv__icon" />
                <img src={Il} alt="Ilya Avatar" className="reviews-tv__avatar_2" />

				<div className="reviews-tv__author-wrapper">
					<a
                                className="reviews-tv__author"
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{ textDecoration: 'none' }}
                                href="https://g.co/kgs/rUdW8zX"
                            >
                                Ilya Vlasov
                            </a>
                            <div className="reviews-tv__date">June 1, 2024</div>
				<div className="reviews-tv__stars">★★★★★</div>
				</div>

				<div className="reviews-tv__content">
					<p>
                            I hired HubPro for office furniture assembly and couldn't be happier! They're not just a team, they're a professional force. The job was done efficiently, with attention to detail. I highly recommend HubPro for reliable and professional service. Fantastic job!
                            </p>
				</div>
			</div>

			<div className="reviews-tv__review">
				<img src={Google_Reviews} alt="Google Icon" className="reviews-tv__icon" />
                <img src={Buzz} alt="Buzz Avatar" className="reviews-tv__avatar_2" />

				<div className="reviews-tv__author-wrapper">
					<a
                                className="reviews-tv__author"
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{ textDecoration: 'none' }}
                                href="https://g.co/kgs/1jCjeQH"
                            >
                                Buzz Love
                            </a>
                            <div className="reviews-tv__date">April 17, 2024</div>
				<div className="reviews-tv__stars">★★★★★</div>
				</div>

				<div className="reviews-tv__content">
					<p>
                            HubPro came to my home to mount several TVs and set up a soundbar, and they did an amazing job! The technicians were prompt, professional, and extremely knowledgeable. They not only mounted the TVs perfectly ...
                            </p>
				</div>
			</div>

			<div className="reviews-tv__review">
				<img src={Google_Reviews} alt="Google Icon" className="reviews-tv__icon" />
                <img src={El} alt="Ilya Avatar" className="reviews-tv__avatar_2" />

				<div className="reviews-tv__author-wrapper">
					<a
                                className="reviews-tv__author"
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{ textDecoration: 'none' }}
                                href="https://maps.app.goo.gl/Aszx4C7npHXKKi5N9"
                            >
                                Elias S.
                            </a>
                            <div className="reviews-tv__date">March 8, 2024</div>
				<div className="reviews-tv__stars">★★★★★</div>
				</div>

				<div className="reviews-tv__content">
					<p>
                            I ordered the assembly of a wardrobe. The technician arrived
                                on time, did everything quickly and efficiently. The price was
                                very reasonable. I recommend!
                            </p>
				</div>
			</div>
		</Slider>
	</div>
</section>
    );
}

export default ReviewsTV;
