import React from "react";
import ContactForm from "../ContactForm";

function BookNow() {
  return (
    <main>
      <ContactForm />
    </main>
  );
}

export default BookNow;
