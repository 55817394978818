import React, { useState } from 'react';
import { Link } from 'react-router-dom';

function BurgerMenu() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <div className="burger-menu-container">
      <div className={`burger-menu-icon ${isMenuOpen ? 'open' : ''}`} onClick={toggleMenu}>
        {isMenuOpen ? '✕' : '☰'}
      </div>
      {isMenuOpen && (
        <nav className="burger-menu-nav">
          <ul className="burger-menu-list">
            <li className="burger-menu-item">
              <Link className="burger-menu-link" to="/" onClick={toggleMenu}>Home</Link>
            </li>
            <li className='burger-menu-item'>
          <Link className="burger-menu-link" to="/handyman_services" onClick={toggleMenu}>Handyman Services</Link>
        </li>
            <li className="burger-menu-item">
              <Link className="burger-menu-link" to="/furniture_assembly" onClick={toggleMenu}>Furniture Assembly</Link>
            </li>
            <li className="burger-menu-item">
              <Link className="burger-menu-link" to="/tv_mounting" onClick={toggleMenu}>TV Mounting</Link>
            </li>
            <li className="burger-menu-item">
              <Link className="burger-menu-link" to="/about" onClick={toggleMenu}>About</Link>
            </li>
            <li className="burger-menu-item">
              <Link className="burger-menu-link" to="/contact" onClick={toggleMenu}>Contact</Link>
            </li>
          </ul>
        </nav>
      )}
    </div>
  );
}

export default BurgerMenu;
