import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../index.css';

import image1 from '../assets/tv_installation_400/tv_mounting_service_1.webp';
import image2 from '../assets/tv_installation_400/tv_mounting_service_2.webp';
import image3 from '../assets/tv_installation_400/tv_mounting_service_3.webp';
import image4 from '../assets/tv_installation_400/tv_mounting_service_4.webp';
import image5 from '../assets/tv_installation_400/tv_mounting_service_5.webp';
import image6 from '../assets/tv_installation_400/tv_mounting_service_6.webp';





// Компонент для кастомной левой стрелки
const PrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={`${className} slick-prev-slider-tv`}
        style={{ ...style, left: '10px'}}
        onClick={onClick}
      />
    );
  };
  
  // Компонент для кастомной правой стрелки
  const NextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={`${className} slick-next-slider-tv`}
        style={{ ...style, right: '80px' }}
        onClick={onClick}
      />
    );
  };
  
  const ImageSlider = () => {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      adaptiveHeight: true,
      nextArrow: <NextArrow />,
      prevArrow: <PrevArrow />,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 1
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };
  
    const images = [
      image4,
      image3,
      image6,
      image1,
      image5,
      image2
    ];
  
    return (
      <div className="image-slider">
        <Slider {...settings}>
          {images.map((image, index) => (
            <div key={index}>
              <img src={image} alt={`Slide ${index + 1}`} />
            </div>
          ))}
        </Slider>
      </div>
    );
  };
  
  export default ImageSlider;