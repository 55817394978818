import React, { useState } from 'react';
import ReviewsHandy from './ReviewsHandyman';
import ImageSlider from '../sliders/ImageSliderHandy';
import Modal from 'react-modal';
import GetQuoteForm from './GetQuoteForm';

import RomanPhoto from '../assets/image/roman.jpg';
import appliance from '../assets/servicesImg/appliance-image.webp';
import Electrical from '../assets/servicesImg/electrical-image.webp';
import Plumbing from '../assets/servicesImg/plumbing-image.webp';
import Ceiling from '../assets/servicesImg/ceiling-fan-image.webp';
import Smart from '../assets/servicesImg/smart-home-image.webp';
import Shelf from '../assets/servicesImg/shelf-mounting-image.webp';
import Maintenance from '../assets/servicesImg/home-maintenance-image.webp';
import Curtains from '../assets/servicesImg/curtains-blinds-image.webp';
import Repairs from '../assets/servicesImg/home-repairs-image.webp';
import Pictures from '../assets/servicesImg/hang-pictures-image.webp';
import Cabinet from '../assets/servicesImg/cabinet-installation-image.webp';
import Fence from '../assets/servicesImg/fence-installation-image.webp';
import Theater from '../assets/servicesImg/home-theater-image.webp';
import garage from '../assets/servicesImg/home-theater-image.webp';
import Delivery from '../assets/servicesImg/delivery.webp';

import tvImage from '../assets/servicesImg/tv_mounting_service_6.webp';
import furnitureImage from '../assets/servicesImg/furniture_assembly_2742.webp';


const city = "Orange County";

const services = [
  { name: "Furniture Assembly", description: "With 15 years of experience, I assemble all types of furniture quickly and efficiently. Enjoy hassle-free, perfectly assembled furniture. Book now for reliable and affordable service!", image: tvImage },
  { name: "TV Mounting", description: "I'm a skilled TV mounting technician. I install all types of TVs and hide the wires for a clean look. Enjoy the best viewing experience with my reliable and affordable service. Book now!", image: furnitureImage },
  { name: "Local Express Delivery & Small Moves", description: "I specialize in organizing swift local delivery from prominent furniture and electronics stores such as IKEA, Living Spaces, Costco, Jerome's Furniture, and others. My services include efficient furniture removal, small-scale local moves, furniture donation drop-offs, furniture disposal, and comprehensive garage clean-outs with debris removal.", image: Delivery },
  { name: "Home Maintenance", description: "Regular maintenance services to keep your home in top condition.", image: Maintenance },
  { name: "Appliance Installation", description: "Professional installation and repair services for household appliances.", image: appliance },
  // { name: "Hang Pictures", description: "Professional hanging of pictures and artwork to enhance your home decor.", image: Pictures },
  // { name: "Shelf Mounting", description: "Secure mounting of shelves to optimize storage space.", image: Shelf },
  { name: "Plumbing Services", description: "Professional plumbing services for residential and commercial spaces.", image: Plumbing },
  // { name: "Home Repairs", description: "General repair services to address various issues around your home.", image: Repairs },
  // { name: "Hanging Curtains & Installing Blinds", description: "Expert assistance in hanging curtains and installing blinds for privacy and aesthetics.", image: Curtains },
  { name: "Garage Door Repair",  description: "Expert repair services for garage doors to ensure smooth operation and security of your garage.", image: Repairs },
  // { name: "Ceiling Fan Installation", description: "Installation of ceiling fans to improve air circulation in your home.", image: Ceiling }
  // { name: "Smart Home Installation", description: "Installation and setup of smart home devices for convenience and efficiency.", image: Smart },
  // { name: "Cabinet Installation", description: "Installation of cabinets for improved storage solutions.", image: Cabinet },
  // { name: "Electrical Help", description: "Expert electrical services for your home or office.", image: Electrical },
  // { name: "Fence Installation & Repair Services", description: "Installation and repair of fences to enhance security and privacy.", image: Fence },
  // { name: "Home Theater Installing", description: "Installation and setup of home theater systems for an immersive entertainment experience.", image: Theater }
];


const Handyman = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedService, setSelectedService] = useState(null);

  const openModal = (serviceName) => {
    setSelectedService(serviceName);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setSelectedService(null);
    setModalIsOpen(false);
  };

  const handleSubmit = (formData) => {
    // Здесь можно добавить логику отправки данных формы
    console.log('Form data submitted from GetQuoteForm:', formData);
    closeModal(); // Закрытие модального окна после отправки формы
  };

  return (
    <section className="handyman-page">



      <div className="handyman-services">
       <div className="handyman-slider">
       <h2 className="about__h1">HubPro.US — Your Trusted Family Handyman in <span style={{ color: 'orange', fontWeight: 'bold' }}>{city}</span></h2>
       <br></br>
       {/* <div className="handyman_header_desc">
          Hi, I'm <strong> Roman</strong>, and this is family business, <strong>HubPro.US</strong> - Your Reliable Handyman with Professional Tools! You can trust me to manage all your home-related needs, including appliance troubleshooting, minor repairs, and maintenance, with a commitment to professionalism. <strong>HubPro.US</strong> - where your home is in capable hands.
        </div> */}


<div className="handyman_container">
    {/* <img
        src={RomanPhoto}
        alt="Roman's photo"
        className="handyman_image"
        style={{
            borderRadius: '50%',
            width: '250px',
            height: '250px',
            marginRight: '20px',
            objectFit: 'cover', // чтобы изображение корректно отображалось в круглом контейнере
        }}
    /> */}
    <div className="handyman_header_desc">
        Hi, I'm <strong>Roman</strong>, and this is family business, <strong>HubPro.US</strong> - Your Reliable Handyman with Professional Tools! You can trust me to manage all your home-related needs, including appliance troubleshooting, minor repairs, and maintenance, with a commitment to professionalism. <strong>HubPro.US</strong> - where your home is in capable hands.
    </div>
</div>



       </div>


      <div className="container_tv_handy">
          <ReviewsHandy city={city} />
          <br />
      </div>


      <div className="handyman-slider">
        <h2 className="about__h1">
            Browsing Through Our {city} Portfolio of Services and Professional Equipment
          </h2>
              <br></br>
              <br></br>
            <ImageSlider />
          
            <br></br>
            <br></br>
        </div>



        {services.map((service, index) => (
          <div key={index} className="handyman-service">
          <img onClick={() => openModal(service.name)} src={service.image} alt={service.name} className="handyman-service-image" />
          <div className="handyman-service-content">
              <h3 className="handyman-service-title">{service.name}</h3>
              <p className="handyman-service-description">{service.description}</p>
              <button onClick={() => openModal(service.name)} className="handyman-service-button">Get a Free Quote</button>
            </div>
          </div>
        ))}

          
        {/* <div className="handyman-slider">
        <h2 className="about__h1">
            Browsing Through Our {city} Portfolio of Services and Professional Equipment
          </h2>
              <br></br>
              <br></br>
            <ImageSlider />
          
            <br></br>
            <br></br>
        </div> */}


          
        <div className="container_tv_handy">
          <h2 className="about__h1">Handyman Service is #1 in <span style={{ color: 'orange', fontWeight: 'bold' }}>{city}</span></h2>
          <br></br>
          <p className="handyman_footer_desc">At HubPro, we're more than just your average handyman service. We're your local family-owned business dedicated to helping you with all your home improvement needs. Our team of skilled professionals is here to tackle any task, big or small, ensuring your home stays in top shape.</p>
          <p className="about__h1">Why choose HubPro for your handyman needs?</p>
          
          <div className="features__list-container">
          <ul className="features__list">
            <li className="features__item"> 
              <p className="features__text_header">Skilled craftsmen ready to handle various household tasks without the need for a license</p>
            </li>
            <li className="features__item">
              <p className="features__text_header">Personalized service tailored to your specific requirements</p>
            </li>
            <li className="features__item">
              <p className="features__text_header">Quick fixes and repairs for everyday household issues like clogged drains and wall repairs</p>
            </li>
          </ul>
          <ul className="features__list">
            <li className="features__item">
              <p className="features__text_header">Expertise in both general handyman services and specialized projects</p>
            </li>
            <li className="features__item">
              <p className="features__text_header">Transparent pricing and estimates provided upfront</p>
              <br></br>
            </li>
            <li className="features__item">
              <p className="features__text_header">Convenient same-day service availability</p> <br></br>
            </li>
          </ul>
          <p className="handyman_footer_desc">No matter the project, our dedicated team arrives equipped with all the necessary tools and materials to get the job done right. Contact us today to experience the HubPro difference!</p>
          </div>
          </div>

      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Get a Free Quote Modal"
        style={{
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)'
          },
          content: {
            top: '50%',
            background: 'linear-gradient(to bottom, #0090ea, #0064cc)', // Исправлено здесь
            left: '50%',
      transform: 'translate(-50%, -50%)',
      padding: '20px',
      maxWidth: '600px', // Можно настроить ширину модального окна
      maxHeight: '1600px', // Можно настроить максимальную высоту модального окна
      overflowY: 'auto', // Разрешить прокрутку содержимого, если оно не помещается
      borderRadius: '10px', // Закругление углов
      boxShadow: '0 0 10px rgba(0, 0, 0, 0.3)', // Тень
      border: 'none' // Убрать окантовку
          }
        }}
      >
        <h5 className="banner-quote__h1">Get a Free Quote for {selectedService}</h5>
        <GetQuoteForm handleSubmit={handleSubmit} />
      </Modal>
    </section>
  );
};

export default Handyman;
