import React from "react";

function SafetySupport() {
  return (
    <section class="safety_support" id="safety_support">
      <div className="support_container">
        <h2>Trained and Certified Technicians</h2>
        <p>
          Our team consists of trained and certified technicians who have
          undergone extensive training in furniture assembly and TV mounting
          techniques. They possess the skills and knowledge required to handle
          various types of furniture and TV models safely and efficiently.
        </p>

        <h2>Comprehensive Safety Measures</h2>
        <p>
          We strictly adhere to safety protocols and guidelines to minimize any
          risks or hazards during the assembly and installation process. Our
          technicians are equipped with the necessary safety equipment and tools
          to ensure a secure working environment.
        </p>

        <h2>Quality Assurance</h2>
        <p>
          We use high-quality materials and equipment to assemble furniture and
          mount TVs, ensuring durability and stability. Each step of the process
          is meticulously inspected to maintain the highest standards of quality
          and safety.
        </p>

        <h2>Secure Mounting Solutions</h2>
        <p>
          When mounting TVs, we utilize secure mounting solutions and brackets
          designed to support the weight and size of your TV. Our technicians
          carefully assess the wall structure and select the appropriate
          mounting hardware to ensure a secure attachment.
        </p>

        <h2>Customer Support and Communication</h2>
        <p>
          Our dedicated customer support team is available to assist you
          throughout the entire process, from scheduling appointments to
          addressing any concerns or inquiries you may have. We prioritize clear
          and transparent communication, keeping you informed about the progress
          of your service and addressing any questions or feedback promptly.
        </p>

        <h2>Post-Service Assistance</h2>
        <p>
          After completing the assembly or installation, we conduct a thorough
          inspection to verify that everything is properly assembled and
          mounted. If you encounter any issues or have additional questions
          after the service, our team is readily available to provide assistance
          and solutions.
        </p>

        <h2>Emergency Response</h2>
        <p>
          In the rare event of emergencies or unforeseen circumstances, we have
          protocols in place to respond promptly and effectively to ensure your
          safety and well-being. Your satisfaction is our priority, and we are
          committed to resolving any issues or concerns to your satisfaction.
        </p>

        <address>
          Orange County Furniture Assembly & TV Mounting Service
          <br />
          9920 Irvine Center Dr,
          <br />
          Irvine, CA 92618
          <br />
          Email: info@hubpro.us
          <br />
          Phone: (949) 438-0343
          <br />
        </address>

        <p>This Safety & Support was last updated on 04/19/2024.</p>

        <p>
          By using our website, you consent to the terms of this Safety &
          Support.
        </p>
      </div>
    </section>
  );
}

export default SafetySupport;
