import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './index.css';

import image8 from './assets/furniture_installation_400/furniture_assembly_3171.webp';
import image4 from './assets/furniture_installation_400/furniture_assembly_3342.webp';
import image5 from './assets/furniture_installation_400/furniture_assembly_2723.webp';
import image6 from './assets/furniture_installation_400/furniture_assembly_2727.webp'; 
import image1 from './assets/furniture_installation_400/furniture_assembly_2730.webp'; 
import image3 from './assets/furniture_installation_400/furniture_assembly_2731.webp'; 
import image9 from './assets/furniture_installation_400/furniture_assembly_2736.webp'; 
import image2 from './assets/furniture_installation_400/furniture_assembly_2741.webp'; 
import image11 from './assets/furniture_installation_400/furniture_assembly_2742.webp';
import image12 from './assets/furniture_installation_400/furniture_assembly_3049.webp';

import assemblyIkeaService from './assets/furniture_installation_400/assembly_ikea_service.webp';
import bookshelfAssembly from './assets/furniture_installation_400/bookshelf_assembly.webp';
import deskAssembly from './assets/furniture_installation_400/desk_assembly.webp';
import furnitureAssemblyServices from './assets/furniture_installation_400/furniture_assembly_services.webp';
import furnitureDeliveryWithAssembly from './assets/furniture_installation_400/furniture_delivery_with_assembly.webp';
import furnitureDisassembly from './assets/furniture_installation_400/furniture_disassembly.webp';
import ikea from './assets/furniture_installation_400/ikea.webp';
import wardrobeAssembly from './assets/furniture_installation_400/wardrobe_assembly.webp';
import moving from './assets/furniture_installation_400/moving.webp';



// Компонент для кастомной левой стрелки
const PrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={`${className} slick-prev`} // Добавляем класс .slick-prev
        style={{ ...style, left: '40px'}}
        onClick={onClick}
      />
    );
  };
  
  // Компонент для кастомной правой стрелки
  const NextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={`${className} slick-next`} // Добавляем класс .slick-next
        style={{ ...style, right: '60px' }}
        onClick={onClick}
      />
    );
  };
  
  const ImageSlider = () => {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 2,
      slidesToScroll: 1,
      adaptiveHeight: true,
      nextArrow: <NextArrow />,
      prevArrow: <PrevArrow />,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 1
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };
  
    const images = [
      assemblyIkeaService,
bookshelfAssembly,
deskAssembly,
moving,
furnitureAssemblyServices,
furnitureDeliveryWithAssembly,
furnitureDisassembly,
ikea,
wardrobeAssembly,



      image12,
      image2,
      image1,
      image3,
      image4,
      image5,
      image6,
      image8,
      image9,
      image11
    ];
  
    return (
      <div className="image-slider">
        <Slider {...settings}>
          {images.map((image, index) => (
            <div key={index}>
              <img src={image} alt={`Slide ${index + 1}`} />
            </div>
          ))}
        </Slider>
      </div>
    );
  };
  
  export default ImageSlider;