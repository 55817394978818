import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { BrowserRouter as Router, Routes, Route, useParams } from 'react-router-dom';
import './index.css';

import Header from './copmonents/Header';
import Home from './copmonents/Home';
import About from './copmonents/About';
import TV_Mounting from './copmonents/TV_Mounting';
import Furniture_Assembly from './copmonents/Furniture_Assembly';
import Contact from './copmonents/Contact';
import FurnitureAssemblyTools from './copmonents/FurnitureAssemblyTools';
import Footer from './copmonents/Footer';
import PrivacyPolicy from './copmonents/PrivacyPolicy';
import TermsOfService from './copmonents/TermsofService';
import SafetySupport from './copmonents/SafetySupport';
import ScrollToTop from './copmonents/ScrollToTop';
import CookiePolicy from './copmonents/CookiePolicy';
import BookNow from './copmonents/BookNow';
import Handyman from './copmonents/Handyman';
import IrvineFurniture from './city/IrvineFurniture';

function App() {
    
  return (
      <div className="App">
        <Header />
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/tv_mounting" element={<TV_Mounting />} />
          <Route path="/furniture_assembly" element={<Furniture_Assembly />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/book_an_appointment" element={<BookNow />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-of-service" element={<TermsOfService />} />
          <Route path="/safety-support" element={<SafetySupport />} />
          <Route path="/cookie-policy" element={<CookiePolicy />} />

          <Route path="/furniture-assembly-tools-for-handyman" element={<FurnitureAssemblyTools />} />
          <Route path="/:service" element={<Furniture_Assembly />} />

          <Route path="/furniture_assembly__irvine" element={<IrvineFurniture />} />
          <Route path="/handyman_services" element={<Handyman />} />
        </Routes>
        <Footer/>
      </div>
  );
}

export default App;
